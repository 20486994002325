<template>
  <b-row>
    <b-col cols="12" class="d-flex justify-content-end">
      <b-button variant="primary" :to="{ name: 'quotation-add' }">
        Add Quotation
      </b-button>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>

    <b-col md="10" class="my-1">
      <b-form-group
        label="Filter"
        label-cols-sm="8"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="quotationList"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(total)="data"> Rs {{ data.item.total.toFixed(2) }} </template>
        <template v-slot:cell(dueDate)="data">
          {{ convertTimestampToDate(data.item.dueDate) }}
        </template>
        <template #cell(createdOn)="data">
          {{ convertTimestampToDate(data.item.createdOn) }}
        </template>
        <template #cell(salesPersonId)="data">
          {{ getSalePersonName(data.item.salesPersonId) }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item v-b-modal.modal-edit :to="getQuotationId(data.item.id)">
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View</span>
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.modal-edit :to="getQuotationEdit(data.item.id)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removequotation(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="d-flex justify-content-center mb-1">
        <b-alert
          variant="warning"
          show
          v-if="quotationList.length === 0"
          class="d-flex justify-content-center"
        >
          <h4 class="alert-heading">No Quotations yet</h4>
        </b-alert>
      </div>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="loading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
      <b-form-group>
      Showing {{ perPage * (currentPage - 1) + 1}} 
      to {{ Math.min(perPage * currentPage, totalRows) }} 
      of {{ totalRows }} entries
        </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BAlert,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BAlert,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      perPage: 5,
      quotationData: [],
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "quotationNo", label: "Quotation No", sortable: true },
        { key: "leadClientName", label: "Customer", sortable: true },
        { key: "leadClientEmail", label: "Customer Email", sortable: true },
        { key: "total", label: "Total" },
        { key: "salesPersonId", label: "Sales Person", sortable: true },
        { key: "dueDate", label: "Due Date", sortable: true },
        { key: "createdOn", label: "Created On", sortable: true},
        "actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("quotationModule", {
      quotationList: "quotations",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
  },

  methods: {
    getSalePersonName(id) {
      const salesPerson = this.SalesPersonList.find((s) => s.id === id);
      return salesPerson ? salesPerson.firstName + " " + salesPerson.lastName : "";
    },
    getquotationData(data) {
      this.quotationData = data;
    },
    convertTimestampToDate(timestamp) {
      return new Date(timestamp).toLocaleDateString();
    },
    removequotation(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeQuotationsAction(id);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your file is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions("quotationModule", [
      "getQuotationsListAction",
      "removeQuotationsAction",
    ]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    getQuotationId(item) {
      return "/quotation/preview/" + item;
    },

    getQuotationEdit(item) {
      return "/quotation/edit/" + item;
    },
  },
  async mounted() {
    await this.getSalesPersonListAction();
    await this.getQuotationsListAction()
      .then((response) => {
        console.log(response);
        this.totalRows = this.quotationList.length;
      })
      .catch((response) => {
        console.log(response);
      });
  },
};
</script>
